import React from 'react';
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Footer, NewsletterSignup } from '../../components';
import classes from './ArticlePageLayout.module.css';
// import Footer from '../Footer';

const PageTemplate = ({ data }) => {
  debugger;
  const { mdx } = data;
  return (
    <article className={classes.article}>
      <Link to="/articles">&lt; Articles</Link>
      <h1>{mdx.frontmatter.title}</h1>
      <MDXRenderer>{mdx.body}</MDXRenderer>
      <hr />
      <h1>Join the wait list</h1>
      <p>
        Interested in using goldie for Simple IRAs for your employees? Join the
        wait list and you'll get updates on the product, as well as an exclusive
        invitation when accounts are available.
      </p>
      <NewsletterSignup />
      <Footer />
    </article>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query ArticleQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
      }
    }
  }
`;
