import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import classes from './NewsletterSignup.module.scss';

const NewsletterSignupForm = () => {
  const [email, setEmail] = useState();
  const [listFields, setListFields] = useState({});
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = async e => {
    if (e && typeof e.preventDefault === 'function') e.preventDefault();
    const response = await addToMailchimp(email, listFields);

    if (response.result === 'success') {
      setSuccessMessage(response.msg);
      setErrorMessage(null);
    }

    if (response.result === 'error') {
      setErrorMessage(response.msg);
    }
  };

  return (
    <>
      {successMessage && (
        <div>
          {successMessage}{' '}
          <span role="img" aria-label="wahoo!">
            🎉
          </span>
        </div>
      )}
      {errorMessage && (
        <div
          class={classes.error}
          dangerouslySetInnerHTML={{ __html: errorMessage }}
        />
      )}

      {!successMessage && (
        <form onSubmit={handleSubmit} className={classes.newsletterForm}>
          <input
            type="text"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="goldie@example.com"
          ></input>
          <button type="submit">Subscribe</button>
        </form>
      )}
    </>
  );
};

export default NewsletterSignupForm;
